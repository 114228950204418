import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  #root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  a {
    color: ${props => props.theme.colors.text};
    text-decoration: none;
  }

  body {
    background: ${props => props.theme.colors.background};
    font-size: 14px;
    color: ${props => props.theme.colors.text};
    font-family: sans-serif;
  }

  .custom-calendar {
    width: 100% !important;
    max-width: 710px;
    background: ${props => props.theme.colors.background} !important;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 0px;
  }
  
  .custom-calendar abbr {
    color: ${props => props.theme.colors.text} !important;
  }

  .custom-calendar .Calendar__day.-ltr.-weekend {
    color: #ff2929 !important;
    opacity: 0.4;
  }

  .custom-calendar .Calendar__weekDays {
    font-weight: bold;
    align-items: center;
    height: 30px;
    background: ${props => props.theme.colors.primary} !important;
  }

  .custom-calendar .Calendar__day {
    color: ${props => props.theme.colors.text} !important;
    border: 2px solid ${props => props.theme.colors.background};
    margin: 0;
  }

  .custom-calendar .Calendar__day[aria-disabled=true] {
    color: ${props => props.theme.colors.text} !important;
    opacity: 0.2;
  }

  .custom-calendar .Calendar__monthText {
    color: ${props => props.theme.colors.text} !important;
    font-size: 1.5rem !important;
    color: #fff !important;
  }

  .custom-calendar .Calendar__yearText {
    color: ${props => props.theme.colors.text} !important;
    font-size: 1.5rem !important;
    color: #fff !important;
  }

  .custom-calendar .Calendar__header {
    background: ${props => props.theme.colors.calendar} !important;
    margin-top: -12px; 
  }

  .custom-calendar .legends {
    margin-left: 20px;
    list-style: none;
    line-height: 30px;
  }

  .custom-calendar .legends li {
    display: flex;
    align-items: center;
  }

  .custom-calendar .legends .yellow {
    border-radius: 50%;
    background: ${props => props.theme.colors.withoutSuplement};
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }

  .custom-calendar .legends .blue {
    border-radius: 50%;
    background: ${props => props.theme.colors.withSuplement};
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }

  .custom-calendar .Calendar__day {
    border-radius: 0px !important;
  }

  @media (max-width: 768px) {
    .faqs {
      margin-top: 30px;
    }
  }

  .custom-calendar .with-suplement {
    background: ${props => props.theme.colors.withSuplement};
    border-radius: 0;
    color: #000 !important;
  }
  
  .custom-calendar .without-suplement {
    background: ${props => props.theme.colors.withoutSuplement};
    border-radius: 0;
    color: #000 !important;
  }

  .MuiPaper-root .MuiDialog-paper {
    background: ${props => props.theme.colors.primary} !important;
  }

  .dialog-responsive h3 {
    color: ${props => props.theme.colors.text} !important;
  }

  .MuiPaper-rounded {
    background: ${props => props.theme.colors.background} !important;
    color: ${props => props.theme.colors.text} !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`;