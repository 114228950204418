import React, { useContext,  useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ThemeContext } from 'styled-components';

import { Tooltip, IconButton, Drawer, List } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AccountCircle, People, Business, Assignment, Info, InvertColors, ChevronLeft, ChevronRight, Menu } from '@material-ui/icons';

import { Container, Content, Title } from './styles';

import Logo from '../../assets/images/pmag.png';
import LogoAlpha from '../../assets/images/pmag-alpha.png';
import LogoSingle from '../../assets/images/pmag-single.png';

interface Props {
  toggleTheme(): void;
}

const drawerWidth = '100%';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));


const Header: React.FC<Props> = ({ toggleTheme }) => {
  const { colors, title } = useContext(ThemeContext);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
 // const { innerWidth } = window;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container>
        <Content>
          <Link to="/">
            <Title>
               
                  <img className="img1" src={title === 'light' ? Logo : LogoAlpha} height={50} alt="Diário Oficial Eletrônico - Prefeitura de Aparecida" />
                  <img className="img2" src={LogoSingle} height={50} alt="Diário Oficial Eletrônico - Prefeitura de Aparecida" />
                
                <span>/</span>
                <h1>Portal <strong>Diário Oficial</strong></h1>
            </Title>
          </Link>
          <div>
            <div className="wightG">
            
            <Tooltip title="Serviços ao Cidadão" placement="bottom">
              <a href="https://www.aparecida.go.gov.br/servicos/cidadao/" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <AccountCircle style={{color: colors.text}} />
                </IconButton>
              </a>
            </Tooltip> 
           
            <Tooltip title="Serviços às Empresas" placement="bottom">
              <a href="https://www.aparecida.go.gov.br/servicos/empresas/" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <Business style={{color: colors.text}} />
                </IconButton>
              </a>
            </Tooltip> 
           
            <Tooltip title="Serviços aos Servidores" placement="bottom">
              <a href="https://www.aparecida.go.gov.br/servicos/servidor/" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <People style={{color: colors.text}} />
                </IconButton>
              </a>
            </Tooltip> 
            
            <Tooltip title="Portal da Transparência" placement="bottom">
              <a href="http://transparencia.aparecida.go.gov.br/portaltransparencia/" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <Assignment style={{color: colors.text}} />
                </IconButton>
              </a>
            </Tooltip> 
            
            <Tooltip title="Acesso à Informação" placement="bottom">
              <a href="http://transparencia.aparecida.go.gov.br/portaltransparencia/p/acessoainformacao/" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <Info style={{color: colors.text}} />
                </IconButton>
              </a>
            </Tooltip> 
            <Tooltip title="Alterar Contraste" placement="bottom">
              <IconButton onClick={toggleTheme}>
                <InvertColors style={{color: colors.text}}  />
              </IconButton>
            </Tooltip>
            </div>
            <div className="wightP">
            
            <IconButton
              color="inherit"
              aria-label="open drawer"
              style={{color: colors.text}}
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
             
            
              <Menu />
            </IconButton> 
            </div>
          </div>
        </Content>      
      </Container>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader} style={{backgroundColor: colors.primary, color: colors.text}}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeft style={{ color: colors.text }} /> : <ChevronRight style={{ color: colors.text }} />} &nbsp;<h5 style={{ fontSize: 17, color: colors.text }}>Fechar</h5>
          </IconButton>
        </div>
        <List style={{ backgroundColor: colors.background, paddingLeft: 1 }}>
          <a href="https://www.aparecida.go.gov.br/servicos/cidadao/" target="_blank" rel="noopener noreferrer">
            <IconButton>
              <AccountCircle style={{color: colors.text}} />
            </IconButton>
            Serviços ao Cidadão
          </a>
        </List>
        <List style={{ backgroundColor: colors.background, paddingLeft: 1 }}>
          <a href="https://www.aparecida.go.gov.br/servicos/empresas/" target="_blank" rel="noopener noreferrer">
            <IconButton>
              <Business style={{color: colors.text}} />
            </IconButton>
            Serviços às Empresas
          </a>
        </List>
        <List style={{ backgroundColor: colors.background, paddingLeft: 1 }}>
          <a href="https://www.aparecida.go.gov.br/servicos/servidor/" target="_blank" rel="noopener noreferrer">
            <IconButton>
              <People style={{color: colors.text}} />
            </IconButton>
            Servições aos Servidores
          </a>
        </List>
        <List style={{ backgroundColor: colors.background, paddingLeft: 1 }}>
          <a href="http://transparencia.aparecida.go.gov.br/portaltransparencia/" target="_blank" rel="noopener noreferrer">
            <IconButton>
              <Assignment style={{color: colors.text}} />
            </IconButton>
            Portal da Transparência
          </a>
        </List>
        <List style={{ backgroundColor: colors.background, paddingLeft: 1 }}>
          <a href="http://transparencia.aparecida.go.gov.br/portaltransparencia/p/acessoainformacao/" target="_blank" rel="noopener noreferrer">
            <IconButton>
              <Info style={{color: colors.text}} />
            </IconButton>
            Acesso à Informação
          </a>
        </List>
      </Drawer>
    </>
  );
};

export default Header;