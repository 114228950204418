export default {
  title: 'light',

  colors: {
    primary: '#f2f2f2',
    secundary: '#D63AF9',
    calendar: '#496488',
    border: '#CCC',

    withSuplement: '#2a80e2',
    withoutSuplement: '#ffdb05',

    background: '#fff',
    text: '#333',
    divider: '#ccc',
    footerBackground: '#e6e6e6'
  },
};