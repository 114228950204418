import React from 'react'

import { Container, Content } from './styles'

import Calendar from '../../components/Calendar';
import Faq from '../../pages/Faq';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <Container>
      <Content>
        <Calendar />
      </Content>
      <Content>
        <Faq limit={7} />
        <Link to="/faq" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, marginRight: 5, fontWeight: 'bold' }}>+ veja mais</Link>
      </Content>
    </Container>
  )
}