import React, { useState, useEffect, useContext } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import { ThemeContext } from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser'

import api from '../../services/api';

import { Container } from './styles';

interface IDiario {
  numero: number;
  publicado: string;
  tipo: number;
}

interface ICustomDaysClassName {
  year: number;
  month: number;
  number: number;
  type: number;
  day: number;
  className: string;
}

const myCustomLocale = {
  // months list by order
  months: [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
  ],

  weekDays: [
    {
      name: 'DOM', // used for accessibility
      short: 'DOM', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
    {
      name: 'SEG',
      short: 'SEG',
      isWeekend: false,
    },
    {
      name: 'TER',
      short: 'TER',
      isWeekend: false,
    },
    {
      name: 'QUA',
      short: 'QUA',
      isWeekend: false,
    },
    {
      name: 'QUI',
      short: 'QUI',
      isWeekend: false,
    },
    {
      name: 'SEX',
      short: 'SEX',
      isWeekend: false,
    },
    {
      name: 'SÁB',
      short: 'SÁB',
      isWeekend: true,
    },
  ],

  weekStartingIndex: 0,

   // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject: any) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date: any) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date: any) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit: any) {
    return digit;
  },

  nextMonth: 'Próximo Mês',
  previousMonth: 'Mês Anterior',
  openMonthSelector: 'Selecionar Mês',
  openYearSelector: 'Selecionar Ano',
  closeMonthSelector: 'Fechar',
  closeYearSelector: 'Fechar',
  defaultPlaceholder: 'Selecione...',

  from: 'de',
  to: 'até',

  digitSeparator: ',',

  yearLetterSkip: 0,

  isRtl: false,
}

const CalendarComponent: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {colors} = useContext(ThemeContext);
  const [events, setEvents] = useState<ICustomDaysClassName[]>([]);
  const [suplementar, setSuplementar] = useState<ICustomDaysClassName[]>([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const minimumDate = {
    year: 2014,
    month: 8,
    day: 1
  }

  const maximumDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  }

  const download = (e: any) => {
    const diario = events.filter((item: any) => (item.day === e.day && item.month === e.month && item.year === e.year ));
    // console.log(diario);

    if (diario.length === 1) {
      window.open(`${process.env.REACT_APP_DOWNLOAD_URL}/${diario[0].number}`)
    } else if (diario.length === 2) {
      setSuplementar(diario);
      handleClickOpen();
    }
  }

  useEffect(() => {
    api.get(`lista`)
      .then(function (response) {
        const diarios = response.data.records.map((diario: IDiario) => {
          const [year, month, day] = diario.publicado.split('-');

          const suplement =  response.data.records.filter((item: any) => (item.publicado === `${year}-${month}-${day}`));

          return {
            year: parseInt(year),
            month: parseInt(month),
            day: parseInt(day.split(' ')[0]),
            number: diario.numero,
            type: diario.tipo,
            className: suplement.length === 1 ? 'without-suplement' : 'with-suplement'
          }
        });

        setEvents(diarios);
      })
      .catch(function (err) {
        console.error(err);
      });

  }, []);

  return (
    <Container>
      <Calendar
        calendarClassName="custom-calendar"
        locale={myCustomLocale}
        shouldHighlightWeekends
        onChange={download}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
        renderFooter={() => (
          <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '0.8rem', background: colors.primary, padding: 20 }}>
            <ul className="legends">
              <li><div className="yellow"></div>Diário sem suplemento</li>
              <li><div className="blue"></div>Diário com suplemento</li>
            </ul>
          </div>
        )}
        customDaysClassName={events}
      />

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="dialog-responsive"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
              {suplementar.map((item, index) => {
                  return (
                    ReactHtmlParser(`<a href=${process.env.REACT_APP_DOWNLOAD_URL}/${item.number} target="_blank">
                      <h3>Diário Oficial Ano ${item.year}, Nº ${item.number}</h3><br />
                    </a>`)
                  )
                }
              )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default CalendarComponent;
