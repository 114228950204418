export default {
  title: 'dark',

  colors: {
    primary: '#333',
    secundary: '#C62E65',
    calendar: '#111',
    border: '#000',

    withSuplement: '#ffdb05',
    withoutSuplement: '#fff',

    background: '#222',
    text: '#fff',
    divider: '#222',
    footerBackground: '#111'
  },
};