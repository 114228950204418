import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import { ThemeContext } from 'styled-components'
import { Typography, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

interface IFaq {
  title: string;
  description: string;
}

export default function Faq(props: any) {
  const [faqs, setFaqs] = useState([]);
  const [expanded, setExpanded] = useState();
  const { colors } = useContext(ThemeContext);

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    axios.get(`json/faqs.json`)
      .then(({data}) => setFaqs(data))
  }, []);


  return (
    <>
      <h2 className="faqs" style={{ marginBottom: 10, paddingBottom: 10 }}>FAQ</h2>
      { 
        faqs.slice(0, props.limit).map((faq: IFaq, index) => (
          <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)} style={{ width: '100%', background: colors.background, color: colors.text }}>
            <AccordionSummary
              expandIcon={<ExpandMore style={{ color: colors.text }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography style={{ color: colors.text, opacity: 0.7 }}>{ ReactHtmlParser(faq.title) }</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ color: colors.text}}>
                { ReactHtmlParser(faq.description) }
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </>
  )
}