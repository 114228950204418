import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom'
import { ThemeProvider, DefaultTheme } from 'styled-components';
import usePeristedState from './utils/usePersistedState'

import GlobalStyle, { Container } from './styles/global';
import light from './styles/themes/light';
import dark from './styles/themes/dark';

import Header from './components/Header';
import Footer from './components/Footer';

import Diof from './assets/images/diof.png';
import DiofAlpha from './assets/images/diof-alpha.png';

import Routes from './routes';

function App() {
  const { innerWidth } = window;
  const [theme, setTheme] = usePeristedState<DefaultTheme>('theme', light);

  const toggleTheme = () => {
    setTheme(theme.title === 'light' ? dark : light);
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <GlobalStyle />
        <Header toggleTheme={toggleTheme} />
      
        <Link to="/" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: innerWidth > 768 ? 60 : 30, marginBottom: 10 }}>
          <img src={theme.title === 'light' ? Diof : DiofAlpha} style={{ width: innerWidth > 768 ? '30%' : '70%', height: 'auto' }} alt="Diário Oficial Eletrônico - Prefeitura de Aparecida" />
        </Link>

        <Container>
          <Routes />
        </Container>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
