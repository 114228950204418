import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { IconButton, Tooltip } from '@material-ui/core';
import { Twitter, Facebook, Instagram, YouTube, Room, Phone, Schedule } from '@material-ui/icons';

import { Container, Copyright, ContainerLeft, ContainerRight, Content } from './styles';

import Logo from '../../assets/images/pmag.png';
import LogoAlpha from '../../assets/images/pmag-alpha.png';

export default function Footer() {
  const { colors, title } = useContext(ThemeContext);

  return (
    <Container>
      <Content>
        <ContainerLeft>
          <img src={title === 'light' ? Logo : LogoAlpha} alt="Prefeitura de Aparecida" height='80'/>
          <div className="social-media">
            <Tooltip title="Twitter">
              <a href="https://twitter.com/prefaparecida" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <Twitter style={{color: colors.text}} />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title="Facebook">
              <a href="https://fb.com/PrefAparecida" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <Facebook style={{color: colors.text}} />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title="Youtube">
              <a href="https://youtube.com/user/prefeituraaparecida" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <YouTube style={{color: colors.text}} />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title="Instagram">
              <a href="https://instagram.com/prefaparecida" target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <Instagram style={{color: colors.text}} />
                </IconButton>
              </a>
            </Tooltip>
          </div>
        </ContainerLeft>
        <ContainerRight>
          <span><Room className="icon"/>&nbsp;&nbsp;Rua Gervásio Pinheiro, APM, s/n - Village Garavelo, CEP 74968-500</span>
          <span><Phone className="icon"/>&nbsp;&nbsp;Fone:&nbsp; <a href="tel:6232386786">(62) 3238-6786</a></span>
          <span><Schedule className="icon"/>&nbsp;&nbsp;08h às 11h30 - 13h às 17h30</span>
        </ContainerRight>
      </Content>
      <Copyright>
        <span>Diretoria de Tecnologia da Informação</span>
        <span>Secretaria de Ciência, Tecnologia e Inovação</span>
      </Copyright>
    </Container>
  )
}
